import React, { useEffect, useState } from "react";
import "./SnakeDetailScreen.css";

import { useParams } from "react-router";
import {
  auth_token,
  auth_token_name,
  id_name,
  resource_url,
  snake_details_url,
  snake_family_id_title,
} from "../Webcontroller/Webcontroller.js";

const SnakeDetailScreen = () => {
  const { id } = useParams();
  const [snakeDetails, setSnakeDetails] = useState({});
  const [snakeRef, setSnakeRef] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const formData = new FormData();
    formData.append(auth_token_name, auth_token);
    formData.append(snake_family_id_title, 0);
    formData.append(id_name, id);

    fetch(snake_details_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        setSnakeDetails(data.data);
        setSnakeRef(data.ref);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <div className=" pb-2 mb-5 pt-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-11 col-lg-7  snake_details_div ">
              <div className="col-md-6 col-12 col-sm-12 col-lg-6 mx-auto">
                <img
                  className="img-fluid rounded mx-auto d-block "
                  src={resource_url + snakeDetails.snake_image}
                  alt=""
                />
              </div>

              <div className=" pt-2 pb-2">
                <div className="d-flex details_bg_1 ">
                  <p className="item_name rounded "> Scientific Name </p>
                  <p className="item_details rounded  ">
                    {" "}
                    {snakeDetails.scientific_name}{" "}
                  </p>
                </div>
                <div className="d-flex details_bg_2 ">
                  <p className="item_name rounded"> English Name </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.english_name}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_1 ">
                  <p className="item_name rounded"> Local Name </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.local_name}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_2 ">
                  <p className="item_name rounded"> Family Name </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.family_name}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_1 ">
                  <p className="item_name rounded"> Venom Type </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.venom_type}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_2 ">
                  <p className="item_name rounded"> Active Period </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.active_period}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_1 ">
                  <p className="item_name rounded"> Distribution </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.distributions}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_2 ">
                  <p className="item_name rounded"> Bangladesh </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.bangladesh}{" "}
                  </p>
                </div>

                <div className="d-flex details_bg_1 ">
                  <p className="item_name rounded"> Habit and Habitat </p>
                  <p className="item_details rounded">
                    {" "}
                    {snakeDetails.habit_and_habitat}{" "}
                  </p>
                </div>
                <div className="d-flex details_bg_2 ">
                  <p className="item_name rounded"> Reference </p>
                  <p className="item_details rounded">
                    {snakeRef.map((item, key) => (
                      <div>
                        {item.url === "" ? (
                          <p className="item_details rounded">
                            {" "}
                            {key + 1} . {item.name}
                          </p>
                        ) : (
                          <p className="item_details rounded">
                            {" "}
                            {key + 1} . <a href={item.url}> {item.url}</a>
                          </p>
                        )}
                      </div>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center loading-bg">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default SnakeDetailScreen;
