export const auth_token_name = "auth_token";
export const auth_token =
  "ObHPYZyyIBsLsS4wh6RHuUUi279ui5PV3p8Co++OjOqKxIzyUUoDAWX4q+j9o05x1kCpsS0uWiGTzfTyHOoVCLSLi9Nntgzytc5+u71mU8kkiCdx84xAE0NULbXax96je41IZAMEpxllnzlmr0pg+EHBRgjLhNFOZs/h0Aub5oUIRn4et5G0vgju4qforv1PyCXcGxOeJycxYKtRDOatHkeIuzmg0GmPUBpw4AKowWs+Y55A3beFionCLJwf0V6A9F5IKhp1HKlS70Ujj8sBUYvk45pA3IrjgDIxrd7Gpn5yAZ126jhkTy40YrpbjA==";
export const id_name = "id";
export const snake_family_id_title = "snake_family_id";
export const segment_id_name = "segment_id";
export const Dec = "dec";
export const authority_msg = "Authorization Failed";
export const main_url = "https://vrc.cmc.edu.bd/Files/";
export const web_api = main_url + "web_api/";
export const dashboard_api = main_url + "dashboard_api/";
export const resource_url = main_url + "resources/";

export const snake_details_url = web_api + "snake_details.php";
export const segment_detail_url = web_api + "segment_detail.php";
export const team_member_url = web_api + "team_member.php";
export const gallery_image_url = web_api + "gallery_image_detail.php";
export const gallery_video_url = web_api + "gallery_video_detail.php";
export const medically_imp_url = web_api + "medically_imp.php";
export const family_name_url = web_api + "family_name.php";
export const user_valid_url = dashboard_api + "is_user_valid.php";
export const put_segment_detail_url = dashboard_api + "put_segment_detail.php";
export const put_team_member_url = dashboard_api + "put_team_member.php";
export const put_gallery_video_url = dashboard_api + "put_gallery_video.php";
export const put_gallery_picture_url =
  dashboard_api + "put_gallery_picture.php";
export const put_mic_snake_url = dashboard_api + "put_medically_imp.php";
