import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DocumentScreen from "../DocumentScreen/DocumentScreen";
import HomeScreen from "../Home/HomeScreen";
import NavBar from "../NavbarScreen/NavBar";
import NonVenomousScreen from "../NonVenomousScreen/NonVenomousScreen";
import SnakeDetailScreen from "../SnakeDetailScreen/SnakeDetailScreen";
import WWDVideoScreen from "../VenomCollVideoScreen/VenomCollVideoScreen";
import WWDImageScreen from "../WWDImageScreen/WWDImageScreen";
import "./BrowseRouterScreen.css";

import { useState } from "react";
import ncdc from "../../Resource/NCDC_logo.png";
import cmc from "../../Resource/cmc.png";
import logo from "../../Resource/logo_vrc.png";
import AboutSnakeScreen from "../AboutSnakeScreen/AboutSnakeScreen";
import DashHomeScreen from "../Dashboard/DashHomeScreen/DashHomeScreen";
import Footer from "../Footer/Footer";
import GalleryScreen from "../GalleryScreen/GalleryScreen";
import PublicationScreen from "../PublicationScreen/PublicationScreen";
import SupportScreen from "../SupportScreen/SupportScreen";
import VrcScreen from "../VrcScreen/VrcScreen";
import WhatDoScreen from "../WhatDoScreen/WhatDoScreen";
import DashAuthen from "../Dashboard/DashAuthScreen/DashAuthen";

const BrowseRouterScreen = () => {
  const [navClick, setNavClick] = useState(false);
  const navCancelClick = () => {
    setNavClick(!navClick);
  };
  return (
    <BrowserRouter>
      <div className=" main-div-logo">
        <div className="container d-flex justify-content-between align-items-center ">
          <div className="  ">
            <div>
              <h1 className="vrc-name">
                <a className="web-name" href="/">
                  Venom Research Centre
                </a>
              </h1>
            </div>
            <div>
              <NavBar click={navClick} cancelClick={navCancelClick} />
            </div>
          </div>
          <div className="navbar-logo ">
            <div className=" d-flex justify-content-end  align-items-center">
              <a href="/">
                <img className="nav-img-logo-app pe-2 " src={logo} alt="" />
              </a>
              <a
                href="https://www.ncdc.gov.bd/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="nav-img-logo-app pe-2 " src={ncdc} alt="" />
              </a>
              <a href="http://cmch.gov.bd/" target="_blank" rel="noreferrer">
                <img className="nav-img-logo-app " src={cmc} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="div-navbar-fas">
        <NavBar click={navClick} cancelClick={navCancelClick} />
      </div>

      <div className="div-loader">
        <Routes>
          <Route path="/vrc" element={<VrcScreen />} />
          <Route path="/gallery" element={<GalleryScreen />} />

          <Route path="/support" element={<SupportScreen />} />
          <Route path="/wwd_images" element={<WWDImageScreen />} />
          <Route path="/wwd_video" element={<WWDVideoScreen />} />
          <Route path="/about-snake" element={<AboutSnakeScreen />} />
          <Route path="/non_venom" element={<NonVenomousScreen />} />
          <Route path="/publication" element={<PublicationScreen />} />
          <Route path="/document" element={<DocumentScreen />} />
          <Route path="/what_do" element={<WhatDoScreen />} />

          <Route path="/snake_details/:id" element={<SnakeDetailScreen />} />
          <Route path="/dashboard" element={<DashHomeScreen />} />
          {/* <Route path="/auth" element={<DashAuthen />} /> */}

          <Route exact path="/" element={<HomeScreen />} />
        </Routes>
      </div>

      <div className=" fixed-logo">
        <a href="https://vrcbangladesh.org/">
          <img className="nav-img-logo-main pe-2" src={logo} alt="" />{" "}
        </a>
        <br />
        <br />
        <a href="https://www.ncdc.gov.bd/">
          <img className="nav-img-logo pe-2" src={ncdc} alt="" />{" "}
        </a>
        <br />
        <br />
        <a href="http://cmch.gov.bd/">
          <img className="nav-img-logo " src={cmc} alt="" />
        </a>
      </div>

      <Footer />
    </BrowserRouter>
  );
};

export default BrowseRouterScreen;
