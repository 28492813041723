import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CategoryTitle from "../../Webcontroller/CategoryTitle";
import {
  auth_token_name,
  authority_msg,
  put_gallery_picture_url,
  resource_url,
} from "../../Webcontroller/Webcontroller";
import DeletePopupScreen from "../DeletePopup/DeletePopupScreen";
import "./DashGallery.css";
import DashInsertGalleryPicture from "./DashInsertGalleryPicture";

const DashGalleryPictureScreen = () => {
  const [trainData, setTrainData] = useState([]);
  const [insert, setInsert] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const [id, setId] = useState(0);
  const [imagePath, setImagePath] = useState("");
  const [categId, setCategId] = useState(0);

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    fetchGalleryPictureData(auth_token_value);
  }, []);

  const fetchGalleryPictureData = async (authToken) => {
    const trainData = new FormData();
    trainData.append("state", 1);
    trainData.append(auth_token_name, authToken);

    await fetch(put_gallery_picture_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInsert = () => {
    setInsert(true);
  };

  const handleDelete = async (id, image_path, category_id) => {
    openDeleteModal();
    setId(id);
    setImagePath(image_path);
    setCategId(category_id);
  };
  const popupDeleteItem = async () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append(auth_token_name, auth_token_value);
    formData.append("state", 4);
    formData.append("id", id);
    formData.append("image_path", imagePath);
    formData.append("categ_id", categId);

    await fetch(put_gallery_picture_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        fetchGalleryPictureData(auth_token_value);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
    setIsDeleteModalOpen(false);
  };
  const handleLoad = () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    setInsert(false);
    fetchGalleryPictureData(auth_token_value);
  };

  const mainDiv = () => {
    return (
      <div>
        <div className=" btn-div">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
              <h1 className="">Gallery Picture</h1>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <button
                className="buttonStyle mt-2"
                onClick={() => {
                  handleInsert();
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="pt-3 ">
            {trainData.length > 0 ? (
              <div className="container">
                <div className="row">
                  {trainData.length > 0 &&
                    trainData.map((item, index) => (
                      <div className="pb-5  ">
                        {trainData[index].img_list.length > 0 && (
                          <CategoryTitle name={item.name} />
                        )}

                        <div className="container">
                          <div className="row  justify-content-start ">
                            {trainData[index].img_list.map((item, index) => (
                              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 imagelayout-main-bg">
                                <div className="dash-video">
                                  <a
                                    className="a_pic_link"
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="row image-container">
                                      <img
                                        className=" image-contain  img-fluid p-1"
                                        src={resource_url + item.image}
                                        alt={item.title}
                                      />
                                    </div>
                                    {item.title !== "" && (
                                      <p className="text-center pic_title">
                                        {item.title}
                                      </p>
                                    )}
                                  </a>
                                  <div className="dash-slider-bottombar">
                                    <FontAwesomeIcon
                                      className="delete-icon"
                                      onClick={() => {
                                        handleDelete(
                                          item.id,
                                          item.image,
                                          item.gallery_image_category_id
                                        );
                                      }}
                                      icon={faWindowClose}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center loading-bg">
                <div className="loading-spinner"></div>
              </div>
            )}
          </div>

          <DeletePopupScreen
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            popupDeleteItem={popupDeleteItem}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid ">
      <div>
        {insert ? (
          <DashInsertGalleryPicture handleLoad={handleLoad} />
        ) : (
          mainDiv()
        )}
      </div>
    </div>
  );
};

export default DashGalleryPictureScreen;
