import { motion } from "framer-motion";
import React from "react";
import "./IntroductionScreen.css";

const IntroductionScreen = () => {
  const vrc_title = "Welcome To Venom Research Centre Bangladesh".split(" ");
  const vrc_about =
    "Venom Research Centre (VRC) Bangladesh, was established in 2018 as a project supported by Non-Communicable Disease Control (NCDC) program under the Directorate General of Health Services (DGHS) of the Ministry of Health and Family Welfare, Government of the People's of Bangladesh. It is a collaborative endeavor of the Department of Medicine, Chittagong Medical College; Department of Zoology,  University of Chittagong; Department of Tropical Medicine and Public Health, Goethe University, Frankfurt am Main, Germany; Toxicology  Society of Bangladesh; and Bangladesh Association for Advancement of Tropical Medicine. This centre is hosted and managed by the Chittagong   Medical College.".split(
      " "
    );

  return (
    <div className="intro_background_img">
      <div className="container intro_body">
        <div className=" pt-5 pb-5 introduction-div">
          <h1 className="text-center intro_h1 text-white">
            {vrc_title.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.25,
                  delay: i / 10,
                }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </h1>
          <div className="p-home-text text-white">
            {vrc_about.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: i / 10,
                }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionScreen;
