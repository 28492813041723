import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  auth_token_name,
  authority_msg,
  put_mic_snake_url,
  resource_url,
} from "../../Webcontroller/Webcontroller";
import DeletePopupScreen from "../DeletePopup/DeletePopupScreen";
import DashInsertMIS from "./DashInsertMIS";

const DashMisScreen = () => {
  const [trainData, setTrainData] = useState([]);
  const [insert, setInsert] = useState(false);
  const [update, setUpdate] = useState(false);
  const [state, setState] = useState("");
  const [id, setId] = useState(0);
  const [familyId, setFamilyId] = useState(0);
  const [imagePath, setImagePath] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    fetchMISData(auth_token_value);
  }, []);
  const fetchMISData = async (authToken) => {
    const trainData = new FormData();
    trainData.append("state", 1);
    trainData.append(auth_token_name, authToken);

    fetch(put_mic_snake_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };
  const handleInsert = () => {
    setState("Insert");
    setInsert(true);
  };
  const handleUpdate = (id, familyId) => {
    setState("Update");
    setId(id);
    setFamilyId(familyId);
    setUpdate(true);
  };

  const handleDelete = (id, image_path, familyId) => {
    openDeleteModal();
    setId(id);
    setImagePath(image_path);
    setFamilyId(familyId);
  };

  const handleLoad = () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    setInsert(false);
    setUpdate(false);
    fetchMISData(auth_token_value);
  };
  const popupDeleteItem = async () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append(auth_token_name, auth_token_value);
    formData.append("state", 4);
    formData.append("id", id);
    formData.append("image_path", imagePath);
    formData.append("snake_family_id", familyId);

    await fetch(put_mic_snake_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        fetchMISData(auth_token_value);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
    setIsDeleteModalOpen(false);
  };

  const mainDiv = () => {
    return (
      <div>
        <div className=" btn-div">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
              <h1 className="">Medically Important Snake</h1>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <button
                className="buttonStyle mt-2"
                onClick={() => {
                  handleInsert();
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="container" id="med_imp">
            <div className=" ">
              {trainData.length > 0 ? (
                <div className="row justify-content-center ">
                  {trainData.map((item, index) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 pb-2 mb-5 "
                      key={index}
                    >
                      <div
                        className="main-card h-100 "
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        <img
                          className=" img-fluid p-1"
                          src={resource_url + item.snake_image}
                          alt={item.title}
                        />
                        <div className="p-3 mb-2">
                          <h3
                            className="text-center text-success"
                            style={{ fontSize: "22px" }}
                          >
                            {item.english_name}
                          </h3>
                          <p className="details_text">{item.distributions}</p>
                        </div>

                        <div class="d-flex justify-content-between">
                          <div
                            onClick={() =>
                              handleDelete(
                                item.id,
                                item.snake_image,
                                item.snake_family_id
                              )
                            }
                            class="p-2  col-md-4 text-center item-show item-del"
                          >
                            Delete
                          </div>
                          <div
                            onClick={() =>
                              handleUpdate(item.id, item.snake_family_id)
                            }
                            class="p-2 col-md-4 text-center item-show item-edit"
                          >
                            Edit
                          </div>
                          <div class="p-2  col-md-4 text-center item-show item-read">
                            <Link
                              to={"/snake_details/" + item.id}
                              className=" item-link"
                            >
                              Read
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="row justify-content-center align-items-center loader_div">
                  <div className="d-flex justify-content-center loading-bg">
                    <div className="loading-spinner"></div>
                  </div>
                </div>
              )}
            </div>
            <DeletePopupScreen
              isOpen={isDeleteModalOpen}
              onClose={closeDeleteModal}
              popupDeleteItem={popupDeleteItem}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid ">
      <div>
        {insert || update ? (
          <DashInsertMIS
            state={state}
            handleLoad={handleLoad}
            id={id}
            familyId={familyId}
          />
        ) : (
          mainDiv()
        )}
      </div>
    </div>
  );
};

export default DashMisScreen;
