import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import logo_vrc from "../../Resource/logo_vrc.png";

import {
  auth_token,
  auth_token_name,
  family_name_url,
} from "../Webcontroller/Webcontroller";

const NonVenomousScreen = () => {
  const [trainData, setTrainData] = useState([]);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);

    fetch(family_name_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <div className="bg-cola-body">
      <div className="container pt-5 pb-5 div-cola-contain">
        <Row className="pt-2 pb-5">
          {trainData.map((item) => (
            <Col
              className=" text-center  collaborate-card  mt-3"
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              xxl={3}
            >
              <img
                className="img-fluid med-img"
                src={logo_vrc}
                alt=""
                height={150}
                width={150}
              />
              <div className="pt-3 ">
                <a className="a_link" href={"/about-snake/" + item.id}>
                  <p>{item.name}</p>
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default NonVenomousScreen;
