/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./PictureScreen.css";

import CategoryTitle from "../Webcontroller/CategoryTitle";
import ImageLayout from "../Webcontroller/ImageLayout";
import {
  auth_token,
  auth_token_name,
  gallery_image_url,
} from "../Webcontroller/Webcontroller";

const PictureScreen = () => {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);

    fetch(gallery_image_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setImageData(data.data);
      })
      .catch((error) => {});
  }, []);

  const loading = () => {
    return (
      <div className="pic_main_div" id="pictures">
        <div className="container">
          {imageData.map((item, index) => (
            <div className="pb-5  " key={index}>
              {imageData[index].img_list.length > 0 && (
                <CategoryTitle name={item.name} />
              )}

              <div className="container">
                <div className="row  justify-content-start ">
                  {imageData[index].img_list.map((item, index) => (
                    <ImageLayout
                      key={index}
                      title={item.title}
                      image={item.image}
                      link={item.image}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {imageData.length > 0 ? (
        loading()
      ) : (
        <div className="d-flex justify-content-center loading-bg">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default PictureScreen;
