import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Dec,
  auth_token_name,
  authority_msg,
  put_segment_detail_url,
  resource_url,
  segment_id_name,
} from "../../Webcontroller/Webcontroller";
import DashPdfPopupScreen from "../DashPopup/DashPdfPopupScreen";
import DeletePopupScreen from "../DeletePopup/DeletePopupScreen";

const DashSapScreen = () => {
  const leafletId = 18;
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [invalidImage, setInvalidImage] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const [id, setId] = useState(0);
  const [imagePath, setImagePath] = useState("");
  const [segmentCategoryId, setSegmentCategoryId] = useState(0);

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    fetchSAPData(auth_token_value);
  }, []);
  const fetchSAPData = async (authToken) => {
    const trainData = new FormData();
    trainData.append("state", 1);
    trainData.append(auth_token_name, authToken);
    trainData.append(segment_id_name, leafletId);
    trainData.append(Dec, 1);

    fetch(put_segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
  };

  const handleInsert = () => {
    openModal();
    setShowImage(true);
    setShowTitle(true);
    setLoading(false);
  };
  const popupInsertSubmit = async () => {
    console.log(invalidImage);
    if (!invalidImage) {
      const auth_token_value = sessionStorage.getItem("auth_token");
      const formData = new FormData();
      formData.append("state", 2);
      formData.append(auth_token_name, auth_token_value);
      formData.append("segment_id", leafletId);
      formData.append("title", title);
      formData.append("image", image);

      await fetch(put_segment_detail_url, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          fetchSAPData(auth_token_value);
        })
        .catch((error) => {
          console.log(authority_msg);
        });
    } else {
      console.log("Invalid Image");
    }

    setIsModalOpen(false);
  };
  const handleDelete = async (id, image_path, segment_category_id) => {
    openDeleteModal();
    setId(id);
    setImagePath(image_path);
    setSegmentCategoryId(segment_category_id);
  };
  const popupDeleteItem = async () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append(auth_token_name, auth_token_value);
    formData.append("state", 4);
    formData.append("id", id);
    formData.append("segment_id", segmentCategoryId);
    formData.append("image_path", imagePath);

    await fetch(put_segment_detail_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        fetchSAPData(auth_token_value);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
    setIsDeleteModalOpen(false);
  };

  const mainDiv = () => {
    return (
      <div>
        <div className=" btn-div">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
              <h1 className="">Strategic Action Plan</h1>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <button
                className="buttonStyle mt-2"
                onClick={() => {
                  handleInsert();
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="container research-body ">
            {trainData.length > 0 ? (
              <div>
                <div className=" justify-content-start  pb-5">
                  {trainData.map((item, index) => (
                    <div
                      className="row research-item pt-3 ps-2 dash-research-item"
                      key={index}
                    >
                      <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                        <div className="d-flex justify-content-start ">
                          <p className="  pe-2">{index + 1}.</p>
                          <a
                            className="item-text"
                            target="_blank"
                            rel="noreferrer"
                            href={resource_url + item.link}
                          >
                            <p>{item.title}</p>
                          </a>
                        </div>
                      </div>
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                        <FontAwesomeIcon
                          className="delete-icon"
                          onClick={() => {
                            handleDelete(
                              item.id,
                              item.image,
                              item.segment_category_id
                            );
                          }}
                          icon={faWindowClose}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center loading-bg">
                <div className="loading-spinner"></div>
              </div>
            )}
          </div>

          <DashPdfPopupScreen
            isOpen={isModalOpen}
            onClose={closeModal}
            loading={loading}
            setLoading={setLoading}
            popupCloseSubmit={popupInsertSubmit}
            hint="Add Strategic Actin Plan Data"
            showTitle={showTitle}
            showImage={showImage}
            setTitle={setTitle}
            setImage={setImage}
            setInvalidImage={setInvalidImage}
          />
          <DeletePopupScreen
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            popupDeleteItem={popupDeleteItem}
          />
        </div>
      </div>
    );
  };

  return <div className="container-fluid ">{mainDiv()}</div>;
};

export default DashSapScreen;
