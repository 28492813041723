import React from "react";
import "./ObjectiveScreen.css";

import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.5,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      mass: 0.4,
      damping: 8,
      staggerChildren: 0.5,
      when: "beforeChildren",
    },
  },
};

const childVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const ObjectiveScreen = () => {
  return (
    <div className="bg-object-div" id="objective">
      <div className="container  pb-5 ">
        <motion.div
          className="bg-white  bg-object-card"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <h3 className=" bg-object-h3">Objective</h3>
          <motion.p variants={childVariants} className="bg-objective-p para-1">
            1. Maintenance and expansion of the ability of snake rearing, venom
            collection and research on venom and antivenom in the established
            Venom Research Centre (VRC) in Chittagong Medical College.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-2">
            2. Collection of major medically relevant venomous snake species
            from different geographical locations in Bangladesh.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-1">
            3. Collection of venom from the snakes in captivity at VRC.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-2">
            4. Processing and preservation of the collected venom following
            standard scientific procedures.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-1">
            5. Characterization of the collected venom following standard
            scientific procedures through collaboration with national and
            international institutes or Organizations.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-2">
            6. Testing efficacy (neutralization) of available antivenoms against
            collected venoms in vitro and in vivo through collaboration with
            national and international institutes or Organizations.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-1">
            7. Initiation of process of development of ELISA to detect venom in
            body fluid sample through collaboration with national and
            international institutes or Organizations.
          </motion.p>
          <motion.p variants={childVariants} className="bg-objective-p para-2">
            8. Provide additional educational and research outcomes (including
            public awareness and training of personals) on snakes and snakebite.
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default ObjectiveScreen;
