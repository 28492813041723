/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  auth_token_name,
  authority_msg,
  put_gallery_picture_url,
} from "../../Webcontroller/Webcontroller";
import ModalPopupScreen from "../DashMIS/ModalPopupScreen";

const DashInsertGalleryPicture = ({ handleLoad }) => {
  const [loading, setLoading] = useState(false);
  const [catgData, setCatgData] = useState([]);
  const [title, setTitle] = useState("");
  const [invalidImage, setinvalidImage] = useState(null);
  const [categId, setCategId] = useState(1);
  const [image, setImage] = useState("");

  const [imageFilname, setImageFilname] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [userInfo, setuserInfo] = useState({
    file: [],
    filepreview: null,
  });

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    fetchGalleryPictureData(auth_token_value);
  }, []);

  const fetchGalleryPictureData = async (authToken) => {
    const trainData = new FormData();
    trainData.append("state", 1);
    trainData.append(auth_token_name, authToken);

    await fetch(put_gallery_picture_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setCatgData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let reader = new FileReader();
  const handleSelectedImage = (event) => {
    const imageFile = event.target.files[0];
    const fileName = event.target.files[0].name;
    setImage(imageFile);
    setImageFilname(fileName);

    if (!imageFile) {
      setinvalidImage("Please select image.");
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
      setinvalidImage("Please select valid image JPG,JPEG,PNG");
      return false;
    }
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        //------------- Resize img code ----------------------------------
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 800;
        var MAX_HEIGHT = 800;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], imageFilname, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setuserInfo({
              ...userInfo,
              file: file,
              filepreview: URL.createObjectURL(imageFile),
            });
          },
          "image/jpeg",
          1
        );
        setinvalidImage(null);
      };
      img.onerror = () => {
        setinvalidImage("Invalid image content.");
        return false;
      };
      //debugger
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };

  const handleDropdownValue = (event) => {
    setCategId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    openModal();
  };

  const popupCloseSubmit = () => {
    const authTokenValue = sessionStorage.getItem("auth_token");
    setIsModalOpen(false);
    setLoading(true);
    const formData = new FormData();
    formData.append("state", 2);
    formData.append(auth_token_name, authTokenValue);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("categ_id", categId);

    fetch(put_gallery_picture_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        handleLoad();
        setLoading(false);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };
  return (
    <div className="insert-main-div">
      {!loading ? (
        <div className="form-div">
          <div>
            <p className="text-center insert-text">Insert Gallery Picture</p>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="pt-3">
              <Form.Label className="label-text">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Scientific Name"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="pt-3">
              <Form.Label className="label-text">Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="Select Image"
                onChange={handleSelectedImage}
                accept="image/*"
              />
            </Form.Group>

            <Form.Group className="pt-3">
              <Form.Label className="label-text">
                Select Gallery Image Category
              </Form.Label>
              <Form.Select
                className="dropdown-btn"
                id="dropdown"
                value={categId}
                defaultValue={1}
                onChange={handleDropdownValue}
              >
                {catgData.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.id + ". " + item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="w-100 mt-3 bg-success mt-5"
            >
              Submit
            </Button>

            <ModalPopupScreen
              isOpen={isModalOpen}
              onClose={closeModal}
              title={"Insert"}
              msg={"Are you sure, you want to insert the data?"}
              popupCloseSubmit={popupCloseSubmit}
            />
          </Form>
        </div>
      ) : (
        <div className="d-flex justify-content-center loading-bg">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default DashInsertGalleryPicture;
