import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import ContactScreen from "../ContactScreen/ContactScreen";
import RescueScreen from "../RescueScreen/RescueScreen";
import RescueSupportScreen from "../RescueSupportScreen/RescueSupportScreen";
import SupportProgramScreen from "../SupportProgram/SupportProgramScreen";

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: { opacity: 1, y: 0 },
};

const Components = ({ serial }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={control}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      {serial === 1 && <SupportProgramScreen />}
      {serial === 2 && <RescueScreen />}
      {serial === 3 && <RescueSupportScreen />}
      {serial === 4 && <ContactScreen />}
    </motion.div>
  );
};

const SupportScreen = () => {
  return (
    <div>
      <Components serial={1} />
      <Components serial={2} />
      <Components serial={3} />
      <Components serial={4} />
    </div>
  );
};

export default SupportScreen;
