import React, { useEffect, useState } from "react";
import "./SliderScreen.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import {
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const ProgramScreen = () => {
  const [trainData, setTrainData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, 14);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});

    goToNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToNext = () => {
    const isLastImage = currentImageIndex === trainData.length - 2;
    const newIndex = isLastImage ? 0 : currentImageIndex + 2;
    setCurrentImageIndex(newIndex);
  };

  return (
    <div className=" program_div">
      <div className="container">
        <h3 className="text-center pb-2 program_title text-white">
          Our Program
        </h3>
        <div className="container">
          <div className="row">
            {trainData.length > 0 &&
              trainData.map(
                (item, key) =>
                  key < 6 && (
                    <div
                      className="pb-5 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center"
                      key={key}
                    >
                      <div className="s-card-1 card-div">
                        <img src={resource_url + item.image} alt="default 1" />

                        <div className="s-card-1-div pt-2 pb-2 w-100">
                          <h3>{item.title}</h3>
                          <p className="text-start ps-2 pe-2"></p>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramScreen;
