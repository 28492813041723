/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./GoogleMapScreen.css";

const GoogleMapScreen = () => {
  return (
    <div className="googlemaps_div">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <iframe
              className="maps-divs"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.831206258773!2d91.82685961093576!3d22.360001440643817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd9474e9d6871%3A0xedb23cfac26755e0!2sVenom%20Research%20Centre!5e0!3m2!1sen!2sbd!4v1715924432974!5m2!1sen!2sbd"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMapScreen;
