import React, { useState } from "react";
import DashCollaborationScreen from "../DashCollaborations/DashCollaborationScreen";
import DashGalleryPictureScreen from "../DashGallery/DashGalleryPictureScreen";
import DashGalleryVideoScreen from "../DashGallery/DashGalleryVideoScreen";
import DashGuidelineScreen from "../DashGuideline/DashGuidelineScreen";
import DashLeafletScreen from "../DashLeaflets/DashLeafletScreen";
import DashMisScreen from "../DashMIS/DashMisScreen";
import DashMiceRearingScreen from "../DashMiceRearing/DashMiceRearingScreen";
import DashOurProgramScreen from "../DashOurProgram/DashOurProgramScreen";
import DashPosterScreen from "../DashPoster/DashPosterScreen";
import DashPresentationScreen from "../DashPresentations/DashPresentationScreen";
import DashPreservationScreen from "../DashPreservation/DashPreservationScreen";
import DashResearchPaperScreen from "../DashResearchPapers/DashResearchPaperScreen";
import DashSapScreen from "../DashSAP/DashSapScreen";
import DashSliderScreen from "../DashSlider/DashSliderScreen";
import DashSnakeCollectionScreen from "../DashSnakeCollection/DashSnakeCollectionScreen";
import DashSnakeRearingScreen from "../DashSnakeRearing/DashSnakeRearingScreen";
import DashSnakeRescueScreen from "../DashSnakeRescue/DashSnakeRescueScreen";
import DashSponsorScreen from "../DashSponsors/DashSponsorScreen";
import DashSupportProgramScreen from "../DashSupportPrograms/DashSupportProgramScreen";
import DashTeamMemberScreen from "../DashTeamMember/DashTeamMemberScreen";
import DashVCImageScreen from "../DashVenomCollection/DashVCImageScreen";
import DashVCVideoScreen from "../DashVenomCollection/DashVCVideoScreen";
const DashBoardScreen = () => {
  const [currentContentKey, setCurrentContentKey] = useState("Slider Screen");
  const contentMap = {
    "Slider Screen": <DashSliderScreen />,
    "Our Program": <DashOurProgramScreen />,
    Collaborations: <DashCollaborationScreen />,
    "Team Members": <DashTeamMemberScreen />,
    Sponsors: <DashSponsorScreen />,
    "Medically Important Snake": <DashMisScreen />,
    Posters: <DashPosterScreen />,
    "Research Papers": <DashResearchPaperScreen />,
    Presentation: <DashPresentationScreen />,
    Leaflets: <DashLeafletScreen />,
    "Who Guidelines": <DashGuidelineScreen />,
    "Strategic Action Plan": <DashSapScreen />,
    Pictures: <DashGalleryPictureScreen />,
    Videos: <DashGalleryVideoScreen />,
    "Snake Collection": <DashSnakeCollectionScreen />,
    "Snake Rearing": <DashSnakeRearingScreen />,
    "Venom Collection Images": <DashVCImageScreen />,
    "Venom Collection Videos": <DashVCVideoScreen />,
    "Venom Preservation": <DashPreservationScreen />,
    "Mice Rearing": <DashMiceRearingScreen />,
    "Support Programs": <DashSupportProgramScreen />,
    "Snake Rescue": <DashSnakeRescueScreen />,
  };
  const jsonList = [
    {
      id: 1,
      name: "Home",
      sublist: ["Slider Screen", "Our Program"],
    },
    {
      id: 2,
      name: "VRC",
      sublist: ["Collaborations", "Team Members", "Sponsors"],
    },
    {
      id: 3,
      name: "About Snakes",
      sublist: ["Medically Important Snake"],
    },
    {
      id: 4,
      name: "Publications",
      sublist: ["Posters", "Research Papers"],
    },
    {
      id: 5,
      name: "Documents",
      sublist: [
        "Presentation",
        "Leaflets",
        "Who Guidelines",
        "Strategic Action Plan",
      ],
    },
    {
      id: 6,
      name: "Gallery",
      sublist: ["Pictures", "Videos"],
    },
    {
      id: 7,
      name: "What we do",
      sublist: [
        "Snake Collection",
        "Snake Rearing",
        "Venom Collection Images",
        "Venom Collection Videos",
        "Venom Preservation",
        "Mice Rearing",
      ],
    },
    {
      id: 8,
      name: "Support Programs",
      sublist: ["Support Programs", "Snake Rescue"],
    },
  ];
  const [expandedItems, setExpandedItems] = useState([]);
  const toggleItem = (id) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(id)
        ? prevExpandedItems.filter((itemId) => itemId !== id)
        : [...prevExpandedItems, id]
    );
  };

  return (
    <div>
      <div className="col-md-12  d-flex justify-content-center ">
        <div className="col-md-3 ">
          <ul>
            {jsonList.map((item) => (
              <li key={item.id} className="li-item-main">
                <div className="d-flex" onClick={() => toggleItem(item.id)}>
                  <p className="w-100">{item.name}</p>
                  <p className=" text-end">
                    {expandedItems.includes(item.id) ? " ▲" : " ▼"}
                  </p>
                </div>
                {expandedItems.includes(item.id) && (
                  <ul>
                    {item.sublist.map((subitem, index) => (
                      <li
                        key={index}
                        className="li-item text-center"
                        onClick={() => setCurrentContentKey(subitem)}
                      >
                        {subitem}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-9">{contentMap[currentContentKey]}</div>
      </div>
    </div>
  );
};

export default DashBoardScreen;
