/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";

import "./CollaborationScreen.css";

import {
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

import Row from "react-bootstrap/Row";
import CardLayout from "../Webcontroller/CardLayout";

const CollaborationScreen = () => {
  const [collaborateData, setCollaborateData] = useState([]);

  useEffect(() => {
    const ordinateData = new FormData();
    ordinateData.append(auth_token_name, auth_token);
    ordinateData.append(segment_id_name, 6);

    fetch(segment_detail_url, {
      method: "POST",
      body: ordinateData,
    })
      .then((res) => res.json())
      .then((data) => {
        setCollaborateData(data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="bg-cola-body" id="collaboration">
      <h3 className="h3-cola">Collaboration</h3>
      <div className="container pt-5 pb-5 div-cola-contain">
        <Row className="pt-2 pb-5">
          {collaborateData.map((item, key) => (
            <CardLayout
              key={key}
              logo={resource_url + item.image}
              title={item.title}
              link={resource_url + item.link}
            />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default CollaborationScreen;
