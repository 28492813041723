import React from "react";
import { resource_url } from "./Webcontroller";
import "./Webcontroller.css";

const ImageLayout = ({ title, image, link }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 imagelayout-main-bg">
      <div className="">
        <a className="a_pic_link" href={link} target="_blank" rel="noreferrer">
          <div className="row image-container">
            <img
              className=" image-contain  img-fluid p-1"
              src={resource_url + image}
              alt={title}
            />
          </div>
          {title !== "" && <p className="text-center pic_title">{title}</p>}
        </a>
      </div>
    </div>
  );
};

export default ImageLayout;
