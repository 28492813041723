import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./DashTeamMemberScreen.css";

const DashTeamMemberPopup = ({
  isOpen,
  onClose,
  popupCloseSubmit,
  hint,
  title,
  setTitle,
  currentValue,
  setCurrentValue,
  setImageLink,
  setInvalidImage,
}) => {
  const [imageFilname, setImageFilname] = useState("");
  const [userInfo, setuserInfo] = useState({
    file: [],
    filepreview: null,
  });

  if (!isOpen) return null;
  let reader = new FileReader();
  const handleSelectedImage = (event) => {
    const imageFile = event.target.files[0];
    const fileName = event.target.files[0].name;
    setImageLink(imageFile);
    setImageFilname(fileName);

    if (!imageFile) {
      setInvalidImage(true);
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
      setInvalidImage(true);
      return false;
    }
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 800;
        var MAX_HEIGHT = 800;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], imageFilname, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setuserInfo({
              ...userInfo,
              file: file,
              filepreview: URL.createObjectURL(imageFile),
            });
          },
          "image/jpeg",
          1
        );
        setInvalidImage(false);
      };
      img.onerror = () => {
        setInvalidImage(true);
        return false;
      };
      //debugger
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };
  const handleDropdownValue = (event) => {
    setCurrentValue(event.target.value);
  };
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ background: "white", padding: 20, borderRadius: 5 }}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{hint}</h5>
              <FontAwesomeIcon
                className="close-popup-btn"
                onClick={() => {
                  onClose();
                }}
                icon={faWindowClose}
              />
            </div>
            <div class="modal-body pt-3 pb-3">
              <div className="text-start">
                <input
                  className="title-input"
                  type="text"
                  id="title"
                  value={title}
                  placeholder="Enter the team member name..."
                  onChange={(e) => setTitle(e.target.value)}
                />
               
                <select
                  className="dropdown-btn"
                  id="dropdown"
                  value={currentValue}
                  defaultValue={currentValue}
                  onChange={handleDropdownValue}
                >
                  <option value="0">Select any Team Category</option>
                  <option value="1">Principal Investigator</option>
                  <option value="2">Co-Investigators</option>
                  <option value="3">Research Associates</option>
                  <option value="4">Research Assistants</option>
                  <option value="5">Research Interns</option>
                </select>

                <input
                  className="select-image"
                  type="file"
                  onChange={handleSelectedImage}
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                onClick={() => popupCloseSubmit()}
                type="button"
                class="btn btn-success"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashTeamMemberPopup;
