import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import DocumentUrlScreen from "./DocumentUrlScreen";

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: { opacity: 1, y: 0 },
};

const Components = ({ serial }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="col-md-12"
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={control}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      {serial === 1 && (
        <DocumentUrlScreen
          name="Presentation"
          seg_id={15}
          screen_id="presentation"
        />
      )}
      {serial === 2 && (
        <DocumentUrlScreen name="Leaflets" seg_id={16} screen_id="leaflets" />
      )}
      {serial === 3 && (
        <DocumentUrlScreen
          name="Who Guidelines"
          seg_id={17}
          screen_id="who-guideline"
        />
      )}
      {serial === 4 && (
        <DocumentUrlScreen
          name="Strategic Action Plan"
          seg_id={18}
          screen_id="strategic-action-plan"
        />
      )}
    </motion.div>
  );
};

const DocumentScreen = () => {
  return (
    <div>
      <Components serial={1} />
      <Components serial={2} />
      <Components serial={3} />
      <Components serial={4} />
    </div>
  );
};

export default DocumentScreen;
