import React from "react";
import "./ResearchScreen.css";

import { useEffect, useState } from "react";
import {
  Dec,
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const ResearchScreen = () => {
  const [trainData, setTrainData] = useState([]);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, 12);
    trainData.append(Dec, 1);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="container research-body" id="research">
      <h4 className="medi-title text-center pt-5">Research Papers</h4>

      {trainData.length > 0 ? (
        <div className=" justify-content-start  pb-5">
          {trainData.map((item, index) => (
            <div
              key={index}
              className="d-flex justify-content-start research-item pt-3 ps-2"
            >
              <p className="  pe-2">{index + 1}.</p>
              <a
                className="item-text"
                target="_blank"
                rel="noreferrer"
                href={resource_url + item.link}
              >
                <p>{item.title}</p>
              </a>
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center loading-bg">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default ResearchScreen;
