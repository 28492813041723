import React, { useEffect, useState } from "react";
import {
    auth_token_name,
    authority_msg,
    user_valid_url,
} from "../../Webcontroller/Webcontroller";
import DashAuthScreen from "../DashAuthScreen/DashAuthScreen";
import DashBoardScreen from "./DashBoardScreen.js";
import "./DashHomeScreen.css";
const DashHomeScreen = () => {
  const [isUserValid, setIsUserValid] = useState(false);

  useEffect(() => {
    fetchUserValid();
  }, []);
  const fetchUserValid = async () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append(auth_token_name, auth_token_value);

    await fetch(user_valid_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsUserValid(!data.error);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };

  return <div>{isUserValid ? <DashBoardScreen /> : <DashAuthScreen />}</div>;
};

export default DashHomeScreen;
