/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller.js";
import "./SponsorScreen.css";

import CategoryTitle from "../Webcontroller/CategoryTitle";

const SponsorScreen = () => {
  const [trainData, setTrainData] = useState([]);

  useEffect(() => {
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, 7);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <div className="bg-cola-body" id="sponsor">
      {trainData.length > 0 && <CategoryTitle name="Sponsors" />}
      <div className="container  div-cola-contain">
        <Container>
          <Row>
            {trainData.map((item, key) => (
              <Col
                className=" text-center mb-2 "
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                key={key}
              >
                <a href={item.link} target="_blank">
                  <img
                    className="donner-img "
                    src={resource_url + item.image}
                    alt=""
                    height={200}
                    // width={400}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SponsorScreen;
