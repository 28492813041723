import React from "react";
// import {motion} from 'framer-motion/dist/framer-motion'

import "./HomeScreen.css";

import CarouselScreen from "../CarouselScreen/CarouselScreen";
import GoogleMapScreen from "../GoogleMapScreen/GoogleMapScreen";
import IntroductionScreen from "../IntroductionScreen/IntroductionScreen";
import ProgramScreen from "../SliderScreen/ProgramScreen";

const HomeScreen = () => {
  return (
    <div>
      <CarouselScreen />
      <IntroductionScreen />
      <ProgramScreen />

      {/* <SliderScreen /> */}
      <GoogleMapScreen />
    </div>
  );
};

export default HomeScreen;
