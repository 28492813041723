import React from "react";
import "./Footer.css";

import { Link } from "react-router-dom";
import fb_icon from "../../components/Snake-Pic/fb_icon.png";
import github from "../../components/Snake-Pic/github.png";
import gmail_icon from "../../components/Snake-Pic/gmail.png";
import linkedin_icon from "../../components/Snake-Pic/linkedin.png";
import twitter_icon from "../../components/Snake-Pic/twitter.png";
import youtube_icon from "../../components/Snake-Pic/youtube.png";

const Footer = () => {
  return (
    <div className=" mt-auto py-3  footer-div ">
      <div className="col-md-12  d-flex justify-content-center  mt-5 ">
        <div className=" row col-md-10 col-11 d-flex justify-content-center div-footer ">
          <div className="col-md-3 col-sm-6 col-6 footer-nav ">
            <p>
              <Link to="/vrc#team_member" className="text-white">
                Team Members
              </Link>
            </p>
            <p>
              <Link to="/vrc#about_vrc" className="text-white">
                About Us
              </Link>
            </p>
            <p>
              <Link to="/support#office_contact" className="text-white">
                Contact Us
              </Link>
            </p>
          </div>
          <div className="col-md-5 col-sm-12  col-12 ">
            <h4 className="text-white">Career Opportunity</h4>
            <ul className="order-list text-white">
              <li>
                {" "}
                -
                <a className="text-white" href="/">
                  Snake Rescue Training
                </a>
              </li>
              <li>
                {" "}
                -
                <a className="text-white" href="/">
                  Research Internship
                </a>
              </li>
            </ul>

            <h4 className="text-white">Important links</h4>
            <ul className="order-list text-white">
              <li>
                -
                <a
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/groups/853651011398715"
                >
                  Snakebite Study Club of Bangladesh
                </a>
              </li>
              <li>
                -
                <a
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/groups/1445728405628658"
                >
                  Snake Awareness
                </a>
              </li>
              <li>
                -
                <a
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=100064876776628"
                >
                  Snake Rescue Team in Chittagong
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12  col-12  text-white ">
            <p className="text-white">Follow us on:</p>

            <div className="d-flex justify-content-center align-items-center ">
              <a href="/">
                <img src={fb_icon} alt="" className="icon-style" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCx2ow7O0Pt7l6mScKexnLYA"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube_icon} alt="" className="icon-style " />
              </a>
              <a href="/">
                <img src={twitter_icon} alt="" className="icon-style pr-2" />
              </a>
              <a
                href="mailto:centrevenomresearch@gmail.com?subject=What about your problem?"
                target="_blank"
                rel="noreferrer"
              >
                <img src={gmail_icon} alt="" className="icon-style" />
              </a>
            </div>

            <div className="text-start ">
              <p>
                <b>Copyright to:</b> <br />
                <i className="ml-3">Venom Research Centre</i>{" "}
              </p>
            </div>
            <p>
              <b>Design and Developed by</b> <br />
              <i className="ml-3">Sarose datta</i> <br />
              <i className="ml-3">Computer Science & Engineering, </i> <br />
              <i className="ml-3">University of Chittagong</i>
              <p>Follow in:</p>
              <div className="d-flex justify-content-center align-items-center ">
                <a
                  href="https://www.linkedin.com/in/sarose-datta/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedin_icon}
                    alt=""
                    className="icon-style mr-2 logo-size"
                  />
                </a>
                <a
                  href="https://www.facebook.com/sd.saruj.97"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fb_icon} alt="" className="icon-style mr-2" />
                </a>
                <a
                  href="https://github.com/Saruj-chy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={github} alt="" className="icon-style" />
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className=" text-center">
            <p className="text-white">
              © Copy Right to : Venom Research Centre, Bangladesh
            </p>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
