import React from "react";
import "./RescueSupportScreen.css";

const RescueSupportScreen = () => {
  return (
    <div className="bg-div" id="rescue_support">
      <div className="container pt-5 pb-5 ">
        <div className="bg-white pt-5 pb-5 bg-card">
          <h3 className=" bg-rescue-h3 text-center">For Snake Rescue </h3>
          <div className="ps-2">
            <p className="p-contact ">
              <b className="b-rescue-vrc">
                For Snake Rescue help Please Contact
              </b>{" "}
            </p>
            <div className="ms-5 ps-5">
              <p className="">
                <b>+880 1857966486 (9AM to 5PM)</b> <br />
                <b>+880 1912101978</b> <br />
                <b>+880 1712994394</b> <br />
                <b>+880 1988330891</b> <br />
                <b>+880 1710964864</b> <br />
                <b>+880 1914827596</b> <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescueSupportScreen;
