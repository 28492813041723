import React from "react";
import { Col } from "react-bootstrap";

import logo_vrc from "../../Resource/logo_vrc.png";

const CardLayout = ({ logo, title, link }) => {
  return (
    <Col
      className=" text-center  collaborate-card  mt-3"
      xs={12}
      sm={6}
      md={4}
      lg={4}
      xl={3}
      xxl={3}
    >
      <img
        className="img-fluid med-img"
        src={logo !== "" ? logo : logo_vrc}
        alt=""
        height={150}
        width={150}
      />
      <div className="pt-3 ">
        <a
          className="a_link"
          target={link !== "" ? "_blank" : ""}
          href={link}
          rel="noreferrer"
        >
          <p>{title}</p>
        </a>
      </div>
    </Col>
  );
};

export default CardLayout;
