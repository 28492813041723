import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import MedicallyImpScreen from "../MedicallyImpScreen/MedicallyImpScreen";

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: { opacity: 1, y: 0 },
};

const Components = ({ serial }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={control}
      transition={{ duration: 0.5, delay: 0.25 }}
    >
      {serial === 1 && <MedicallyImpScreen />}
    </motion.div>
  );
};

const AboutSnakeScreen = () => {
  return (
    <div>
      <Components serial={1} />
    </div>
  );
};

export default AboutSnakeScreen;
