/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./NavBar.css";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = ({ click, cancelClick }) => {
  return (
    <>
      <nav className={`navbar navbar-nav ml-auto pe-4 `}>
        <div className="container nav-container ">
          <div className="nav-logo ">
            <a className="nav-logo-a " href="/">
              Venom Research Centre
            </a>
          </div>
          <div className="">
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item ">
                <p className="p-vrc ">VRC</p>
                <div className="dropdown-content">
                  <a href="/vrc#about_vrc" onClick={cancelClick}>
                    About Us
                  </a>
                  <a href="/vrc#objective" onClick={cancelClick}>
                    Objectives
                  </a>

                  <a href="/vrc#collaboration" onClick={cancelClick}>
                    Collaborations
                  </a>
                  <a href="/vrc#team_member" onClick={cancelClick}>
                    Team Members
                  </a>
                  <a href="/vrc#sponsor" onClick={cancelClick}>
                    Sponsors
                  </a>

                  {/* <a href="/" onClick={cancelClick}>
                    Jobs & Careers
                  </a> */}
                </div>
              </li>
              <li className="nav-item">
                <p className="p-vrc">About Snakes</p>

                <div className="dropdown-content">
                  <a href="/about-snake" onClick={cancelClick}>
                    Snakes of Bangladesh
                  </a>
                  <a href="/about-snake#med_imp" onClick={cancelClick}>
                    Medically Important Snake
                  </a>
                  {/* <a href="/non_venom" onClick={cancelClick}>
                    Non-Venomous
                  </a>
                  <a href="/" onClick={cancelClick}>
                    Threatened
                  </a> */}
                </div>
              </li>
              {/* <li className="nav-item">
                <p className="p-vrc">Research</p>

                <div className="dropdown-content">
                  <a href="/" onClick={cancelClick}>
                    On going Research
                  </a>
                  <a href="/" onClick={cancelClick}>
                    Completed Research
                  </a>
                </div>
              </li> */}
              <li className="nav-item">
                <p className="p-vrc">Publications</p>

                <div className="dropdown-content">
                  <a
                    href="/publication#on-going-research"
                    onClick={cancelClick}
                  >
                    On going Research
                  </a>
                  <a href="/publication#poster" onClick={cancelClick}>
                    Posters
                  </a>
                  <a href="/publication#research" onClick={cancelClick}>
                    Research Papers
                  </a>
                </div>
              </li>

              <li className="nav-item">
                <p className="p-vrc">Documents</p>

                <div className="dropdown-content">
                  <a href="/document#presentation" onClick={cancelClick}>
                    Presentation
                  </a>
                  <a href="/document#leaflets" onClick={cancelClick}>
                    Leaflets{" "}
                  </a>
                  {/* <a href="/">Leaflets (Downloadable format)</a> */}
                  <a href="/document#who-guideline" onClick={cancelClick}>
                    Who Guidelines
                  </a>
                  <a
                    href="/document#strategic-action-plan"
                    onClick={cancelClick}
                  >
                    Strategic Action Plan
                  </a>
                </div>
              </li>

              <li className="nav-item">
                <p className="p-vrc">Gallery</p>

                <div className="dropdown-content">
                  <a href="/gallery#pictures" onClick={cancelClick}>
                    Pictures
                  </a>
                  <a href="/gallery#videos" onClick={cancelClick}>
                    Videos
                  </a>
                </div>
              </li>
              <li className="nav-item ">
                <p className="p-vrc"> What we do</p>

                <div className="dropdown-content">
                  <a href="/what_do#snake_collect" onClick={cancelClick}>
                    Snake Collection
                  </a>
                  <a href="/what_do#snake_rear" onClick={cancelClick}>
                    Snake Rearing
                  </a>
                  <a href="#" className="sub-item">
                    Venom Collection
                    <div className="sub-dropdown-content">
                      <a href="/what_do#images" onClick={cancelClick}>
                        Images
                      </a>
                      <a href="/what_do#videos" onClick={cancelClick}>
                        Videos
                      </a>
                    </div>
                  </a>

                  <a href="/what_do#preserve" onClick={cancelClick}>
                    Venom Preservation
                  </a>
                  <a href="/what_do#mice_rear" onClick={cancelClick}>
                    Mice Rearing
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <p className="p-vrc">Support Programs</p>
                <div className="dropdown-content">
                  <a
                    href="/support#support_program"
                    onClick={cancelClick}
                    className=" a-support"
                  >
                    Support Programs
                  </a>
                  <a href="/support#snake_rescue" onClick={cancelClick}>
                    Snake Rescue
                  </a>

                  <a className="sub-item">
                    Contact Us
                    <div className="sub-dropdown-content">
                      <a href="/support#rescue_support" onClick={cancelClick}>
                        Snake Rescue
                      </a>
                      <a href="/support#office_contact" onClick={cancelClick}>
                        Office Contact
                      </a>
                    </div>
                  </a>
                </div>
              </li>

              {/* <li className="nav-item nav-drawer-item">
                <p className="p-vrc" onClick={cancelClick}>
                  Contact Us
                </p>
              </li>

              <li className="nav-item nav-drawer-item">
                <p className="p-vrc" onClick={cancelClick}>
                  On Going Projects
                </p>
              </li> */}
            </ul>
          </div>
          <div className="nav-icon" onClick={cancelClick}>
            {/* <button>hello</button> */}
            {/* <i class="fas">&#xf039;</i> */}
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}
            <FontAwesomeIcon className="font-awesome" icon={faBars} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
