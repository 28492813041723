/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import CardLayout from "../Webcontroller/CardLayout";
import "./TeamMemberScreen.css";

import {
  auth_token,
  auth_token_name,
  resource_url,
  segment_id_name,
  team_member_url,
} from "../Webcontroller/Webcontroller";

const TeamMemberScreen = () => {
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const ordinateData = new FormData();
    ordinateData.append(auth_token_name, auth_token);
    ordinateData.append(segment_id_name, 6);

    fetch(team_member_url, {
      method: "POST",
      body: ordinateData,
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div id="team_member">
      <div className="bg-team">
        <div className="container pb-5 ">
          <div className="bg-white  bg-team-card">
            <h3 className="bg-team-h3 text-center">Team Member</h3>

            <div>
              {categoryData.map((item, index) => (
                <div className="pt-2" key={index}>
                  <h2>{item.name}</h2>

                  <Row>
                    {categoryData[index].team_list.map((item, index) => (
                      <CardLayout
                        key={index}
                        logo={
                          item.image !== ""
                            ? resource_url + item.image
                            : item.image
                        }
                        title={item.name}
                        link={resource_url + item.image}
                      />
                    ))}
                  </Row>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberScreen;
