import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  auth_token,
  auth_token_name,
  medically_imp_url,
  resource_url,
} from "../Webcontroller/Webcontroller";
import "./MedicallyImpScreen.css";

const MedicallyImpScreen = () => {
  const [trainData, setTrainData] = useState([]);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);

    fetch(medically_imp_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <div className="container" id="med_imp">
      <h4 className="medi-title text-center pt-2">Medically Important Snake</h4>
      <div className=" ">
        {trainData.length > 0 ? (
          <div className="row justify-content-center ">
            {trainData.map((item, key) => (
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 pb-2 mb-5 "
                key={key}
              >
                <div
                  className="main-card h-100 "
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <img
                    className=" img-fluid p-1"
                    src={resource_url + item.snake_image}
                    alt={item.title}
                  />
                  <div className="p-3 mb-2">
                    <h3
                      className="text-center text-success"
                      style={{ fontSize: "22px" }}
                    >
                      {item.english_name}
                    </h3>
                    <p className="details_text">{item.distributions}</p>
                  </div>
                  <div className="read_more_div">
                    <Link
                      to={"/snake_details/" + item.id}
                      className="d-flex justify-content-end align-items-end p-1  div_footer  "
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="row justify-content-center align-items-center loader_div">
            <div className="d-flex justify-content-center loading-bg">
              <div className="loading-spinner"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicallyImpScreen;
