import React, { useEffect, useState } from "react";
import {
  Dec,
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";
import "./PosterScreen.css";

const PosterScreen = () => {
  const posterId = 10;
  const [posterLink, setPosterLink] = useState([]);

  useEffect(() => {
    const formData = new FormData();
    formData.append(auth_token_name, auth_token);
    formData.append(segment_id_name, posterId);
    formData.append(Dec, 1);

    fetch(segment_detail_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setPosterLink(data.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <div>
      {posterLink.length > 0 && (
        <div className="poster-main-div" id="poster">
          <h4 className="medi-title text-center pt-2">Posters</h4>

          <div className="container ">
            {posterLink.length > 0 ? (
              <div className="row pb-5">
                {posterLink.map((item, key) => (
                  <div
                    key={key}
                    className="pb-5 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center"
                  >
                    <div className="dash-slider-div-1 slider-card-div">
                      <a href={resource_url + item.image}>
                        <img src={resource_url + item.image} alt="default 1" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center loading-bg">
                <div className="loading-spinner"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PosterScreen;
