import React from "react";

import { useEffect, useState } from "react";
import {
  Dec,
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const DocumentUrlScreen = ({ name, seg_id, screen_id }) => {
  const [trainData, setTrainData] = useState([]);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, seg_id);
    trainData.append(Dec, 1);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container research-body " id={screen_id}>
      {
        trainData.length > 0 && (
          <div>
            <h4 className="medi-title text-center pt-5">{name}</h4>
            <div className=" justify-content-start  pb-5">
              {trainData.map((item, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-start research-item pt-3 ps-2"
                >
                  <p className="  pe-2">{index + 1}.</p>
                  <a
                    className="item-text"
                    target="_blank"
                    rel="noreferrer"
                    href={resource_url + item.link}
                  >
                    <p>{item.title}</p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )
        //  : (
        //   <div className="d-flex justify-content-center loading-bg">
        //     <div className="loading-spinner"></div>
        //   </div>
        // )
      }
    </div>
  );
};

export default DocumentUrlScreen;
