import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  Dec,
  auth_token_name,
  authority_msg,
  put_segment_detail_url,
  segment_id_name,
} from "../../Webcontroller/Webcontroller";
import DashImagePopupScreen from "../DashPopup/DashImagePopupScreen";
import DeletePopupScreen from "../DeletePopup/DeletePopupScreen";

const DashVCImageScreen = () => {
  const segmentId = 20;
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const [id, setId] = useState(0);
  const [segmentCategoryId, setSegmentCategoryId] = useState(0);

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    fetchVCImageData(auth_token_value);
  }, []);

  const fetchVCImageData = async (authToken) => {
    const trainData = new FormData();
    trainData.append("state", 1);
    trainData.append(auth_token_name, authToken);
    trainData.append(segment_id_name, segmentId);
    trainData.append(Dec, 1);

    await fetch(put_segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInsert = () => {
    openModal();
    setShowTitle(true);
    setShowLink(true);
    setLoading(false);
  };

  const handleDelete = async (id, segment_category_id) => {
    openDeleteModal();
    setId(id);
    setSegmentCategoryId(segment_category_id);
  };

  const popupCloseSubmit = async () => {
    if (title !== "" && link !== "") {
      const auth_token_value = sessionStorage.getItem("auth_token");
      const formData = new FormData();
      formData.append(auth_token_name, auth_token_value);
      formData.append("state", 2);
      formData.append("segment_id", segmentId);
      formData.append("title", title);
      formData.append("link_type", 1);
      formData.append("link", link);

      await fetch(put_segment_detail_url, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          fetchVCImageData(auth_token_value);
        })
        .catch((error) => {
          console.log(authority_msg);
        });
    } else {
      alert("Please enter the valid data");
    }

    setIsModalOpen(false);
  };

  const popupDeleteItem = async () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append(auth_token_name, auth_token_value);
    formData.append("state", 4);
    formData.append("id", id);
    formData.append("segment_id", segmentCategoryId);

    await fetch(put_segment_detail_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        fetchVCImageData(auth_token_value);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
    setIsDeleteModalOpen(false);
  };

  const mainDiv = () => {
    return (
      <div>
        <div className=" btn-div">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
              <h1 className="">Venom Collection Videos</h1>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <button
                className="buttonStyle mt-2"
                onClick={() => {
                  handleInsert();
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="pt-3 ">
            {trainData.length > 0 ? (
              <div className="container">
                <div className="row">
                  {trainData.length > 0 &&
                    trainData.map((item, key) => (
                      <Col
                        className=" text-center"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        xxl={4}
                        key={key}
                      >
                        <div className="dash-video pt-3 pb-3">
                          <iframe
                            src={item.link}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="video"
                            className="yt-iframe"
                            allowFullScreen
                          />
                          <p>{item.title}</p>
                          <div className="dash-slider-bottombar">
                            <FontAwesomeIcon
                              className="delete-icon"
                              onClick={() => {
                                handleDelete(item.id, item.segment_category_id);
                              }}
                              icon={faWindowClose}
                            />
                          </div>
                        </div>
                      </Col>
                    ))}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center loading-bg">
                <div className="loading-spinner"></div>
              </div>
            )}
          </div>

          <DashImagePopupScreen
            isOpen={isModalOpen}
            onClose={closeModal}
            loading={loading}
            setLoading={setLoading}
            popupCloseSubmit={popupCloseSubmit}
            hint="Add Venom Collection Video Data"
            showTitle={showTitle}
            showLink={showLink}
            setTitle={setTitle}
            setLink={setLink}
          />
          <DeletePopupScreen
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            popupDeleteItem={popupDeleteItem}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid ">
      <div>{mainDiv()}</div>
    </div>
  );
};

export default DashVCImageScreen;
