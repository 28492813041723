import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CategoryTitle from "../Webcontroller/CategoryTitle";
import {
  auth_token,
  auth_token_name,
  gallery_video_url,
} from "../Webcontroller/Webcontroller";
import "./VideoScreen.css";

const VideoScreen = () => {
  const [videoLink, setVideoLink] = useState([]);

  useEffect(() => {
    const formData = new FormData();
    formData.append(auth_token_name, auth_token);

    fetch(gallery_video_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setVideoLink(data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div>
      <div className="container pt-5 pb-5 " id="videos">
        <CategoryTitle name="All Videos" />

        {videoLink.length > 0 ? (
          <div>
            <Row className="">
              {videoLink.map((item, key) => (
                <Col
                  className=" text-center"
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  key={key}
                >
                  <iframe
                    src={item.link}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="video"
                    // width={400}
                    // height={400}
                    className="yt-iframe"
                    allowFullScreen
                  />
                  <p>{item.title}</p>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="d-flex justify-content-center loading-bg">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoScreen;
