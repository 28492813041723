/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import CategoryTitle from "../Webcontroller/CategoryTitle";
import {
  auth_token,
  auth_token_name,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const VenomCollVideoScreen = () => {
  const segmentId = 20;
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, segmentId);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        data.error === true ? setLoading(false) : setLoading(true);
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);

  const loadingView = () => {
    return (
      <div className="pt-5 pb-5  slider_screen">
        <CategoryTitle name="Venom Collection Videos" />
        <div className="container">
          <div>
            <Row className="">
              {trainData.map((item, key) => (
                <Col
                  key={MediaKeyStatusMap}
                  className=" text-center"
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                >
                  <iframe
                    src={item.link}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="video"
                    // width={400}
                    // height={400}
                    className="yt-iframe"
                    allowFullScreen
                  />
                  <p>{item.title}</p>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div id="videos">
      {trainData.length > 0
        ? loadingView()
        : loading && (
            <div className="d-flex justify-content-center loading-bg">
              <div className="loading-spinner"></div>
            </div>
          )}
    </div>
  );
};

export default VenomCollVideoScreen;
