import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBP6Fr2d4-MMhSfCvM9_bogJCRJWwepaeY",
  authDomain: "venomresearchcentre-f0bbc.firebaseapp.com",
  projectId: "venomresearchcentre-f0bbc",
  storageBucket: "venomresearchcentre-f0bbc.appspot.com",
  messagingSenderId: "1066159981669",
  appId: "1:1066159981669:web:de74dc4977d5de87e6728c",
  measurementId: "G-GWQ81TFS3M"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth };
export default db;