/* eslint-disable no-unused-vars */
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import logo_vrc from "../../../Resource/logo_vrc.png";
import {
  auth_token_name,
  authority_msg,
  put_team_member_url,
  resource_url,
} from "../../Webcontroller/Webcontroller";
import DeletePopupScreen from "../DeletePopup/DeletePopupScreen";
import DashTeamMemberPopup from "./DashTeamMemberPopup";
import "./DashTeamMemberScreen.css";

const DashTeamMemberScreen = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [title, setTitle] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);
  const [image, setImage] = useState("");
  const [invalidImage, setInvalidImage] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const [id, setId] = useState(0);
  const [teamCtgId, setTeamCtgId] = useState(0);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    fetchTeamMemberData(auth_token_value);
  }, []);

  const fetchTeamMemberData = (authToken) => {
    const categoryData = new FormData();
    categoryData.append("state", 1);
    categoryData.append(auth_token_name, authToken);

    fetch(put_team_member_url, {
      method: "POST",
      body: categoryData,
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data.data);
      })
      .catch((error) => {});
  };

  const handleInsert = () => {
    openModal();
  };
  const popupInsertSubmit = async () => {
    if (!invalidImage) {
      const auth_token_value = sessionStorage.getItem("auth_token");
      const formData = new FormData();
      formData.append("state", 2);
      formData.append(auth_token_name, auth_token_value);
      formData.append("team_category_id", currentValue);
      formData.append("name", title);
      formData.append("image", image);

      await fetch(put_team_member_url, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          fetchTeamMemberData(auth_token_value);
        })
        .catch((error) => {
          console.log(authority_msg);
        });
    } else {
      console.log("Invalid Image");
    }
    setIsModalOpen(false);
  };

  const handleDelete = async (id, logo, CtgId) => {
    openDeleteModal();
    setId(id);
    setImagePath(logo);
    setTeamCtgId(CtgId);
  };
  const popupDeleteItem = async () => {
    const auth_token_value = sessionStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append("state", 3);
    formData.append(auth_token_name, auth_token_value);
    formData.append("id", id);
    formData.append("team_category_id", teamCtgId);
    formData.append("image_path", imagePath);

    await fetch(put_team_member_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        fetchTeamMemberData(auth_token_value);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
    setIsDeleteModalOpen(false);
  };
  const CardLayout = (id, title, logo, link, ctgId) => {
    return (
      <Col
        className=" text-center dash-team-member-card mt-3"
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
        xxl={3}
      >
        <img
          className="img-fluid med-img"
          src={logo !== "" ? logo : logo_vrc}
          alt=""
          height={150}
          width={150}
        />
        <div className="dash-slider-bottombar">
          <FontAwesomeIcon
            className="delete-icon"
            onClick={() => {
              handleDelete(id, logo, ctgId);
            }}
            icon={faWindowClose}
          />
        </div>
        <div className="pt-3 ">
          <a
            className="a_link"
            target={link !== "" ? "_blank" : ""}
            href={link}
            rel="noreferrer"
          >
            <p>{title}</p>
          </a>
        </div>
      </Col>
    );
  };

  const mainDiv = () => {
    return (
      <div>
        <div className=" btn-div">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
              <h1 className="">Team Members</h1>
            </div>
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <button
                className="buttonStyle mt-2"
                onClick={() => {
                  handleInsert();
                }}
              >
                + Add
              </button>
            </div>
          </div>

          <div className="pt-3  text-start">
            {categoryData.length > 0 ? (
              <div className="container">
                <div className="row">
                  {categoryData.length > 0 &&
                    categoryData.map((item, key) => (
                      <div className="pt-2">
                        <h2>{item.name}</h2>

                        <Row>
                          {categoryData[key].team_list.map((item, index) =>
                            CardLayout(
                              item.id,
                              item.name,
                              item.image !== ""
                                ? resource_url + item.image
                                : logo_vrc,
                              item.image !== ""
                                ? resource_url + item.image
                                : logo_vrc,
                              item.team_category_id
                            )
                          )}
                        </Row>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center loading-bg">
                <div className="loading-spinner"></div>
              </div>
            )}
          </div>
          <DashTeamMemberPopup
            isOpen={isModalOpen}
            onClose={closeModal}
            hint="Add Team Member Data"
            title={title}
            setTitle={setTitle}
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
            popupCloseSubmit={popupInsertSubmit}
            setImageLink={setImage}
            setInvalidImage={setInvalidImage}
          />
          <DeletePopupScreen
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            popupDeleteItem={popupDeleteItem}
          />
        </div>
      </div>
    );
  };

  return <div className="container-fluid ">{mainDiv()}</div>;
};

export default DashTeamMemberScreen;
