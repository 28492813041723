import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DeletePopupScreen = ({ isOpen, onClose, popupDeleteItem }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ background: "white", padding: 20, borderRadius: 5 }}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title p-2">
                Are you sure, you want to delete this item? 
              </h5>
              <br/>
              <FontAwesomeIcon
              className="close-popup-btn"
                onClick={() => {
                  onClose();
                }}
                icon={faWindowClose}
              />
            </div>

            <div class="modal-footer">
              <button
                onClick={() => onClose()}
                type="button"
                class="btn btn-success"
              >
                No
              </button>

              <button
                onClick={() => popupDeleteItem()}
                type="button"
                class="btn btn-success"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePopupScreen;
