import { motion } from "framer-motion/dist/framer-motion";
import React from "react";

import "./OnGoingResearchScreen.css";

const OnGoingResearchScreen = () => {
  const title = "Research".split(" ");
  const body =
    "The centre conducts research on a wide aspect of snakes and snakebite management. These studies are not only limited to ecology, evolution, genomics, venomics and proteomics but also extends anti-therapeutics of snakebite.".split(
      " "
    );
  return (
    <div className="main-going-research" id="on-going-research">
      <div className="container pt-5 pb-5 ">
        <div className="bg-white text-center bg-go-res-card">
          <h3 className=" go-res-h3">
            {title.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.25,
                  delay: i / 10,
                }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </h3>

          <p className="go-res-p ">
            {body.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: i / 10,
                }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnGoingResearchScreen;
