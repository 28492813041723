import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import "./ScrollButton.css";
// https://www.geeksforgeeks.org/how-to-create-a-scroll-to-top-button-in-react-js/

const loaderVariants = {
  animationOne: {
    x: [0, 0],
    y: [0, -100],
    transition: {
      x: {
        yoyo: Infinity,
        duration: 0.5,
      },
      y: {
        yoyo: Infinity,
        duration: 5,
        ease: "easeIn",
      },
    },
  },
};

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <motion.div
      className="div-scroll"
      variants={loaderVariants}
      animate="animationOne"
    >
      <FaArrowCircleUp
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </motion.div>
  );
};

export default ScrollButton;
