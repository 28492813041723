import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import db, { auth } from "../../../firebase";
import PopupScreen from "../PopupScreen/PopupScreen";
import "./DashAuthScreen.css";

const DashAuthScreen = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const onFormSubmit = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;
        onSnapshot(doc(db, "auth_user", uid), (snapshot) => {
          sessionStorage.setItem("auth_token", snapshot.data().auth_token);
          window.location.reload();
        });
      })
      .catch((error) => {
        openModal();
      });
  };

  const popupCloseSubmit = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="d-flex justify-content-center align-items-center dash-auth-div">
      <div className="dash-sign-div auth-div">
        <h1 className="text-center auth-title">Sign In</h1>
        <Form className="pt-5 pb-5" onSubmit={onFormSubmit}>
          <Form.Group>
            <Form.Label className="input-label">Email </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="pt-3 input-label">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <div className="mt-5 text-end">
            <Button type="submit">Sign In</Button>
          </div>

          <PopupScreen
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Error Authentication"
            msg="You are not eligible to experience the Auth View!"
            popupCloseSubmit={popupCloseSubmit}
            btn={"Close"}
          />
        </Form>
      </div>
    </div>
  );
};

export default DashAuthScreen;
