import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./DashPopup.css";

const DashPdfPopupScreen = ({
  isOpen,
  onClose,
  popupCloseSubmit,
  loading = false,
  setLoading,
  hint,
  showTitle = false,
  showLink = false,
  showImage = false,
  setTitle,
  setLink,
  setImage,
  setInvalidImage,
}) => {
  const [errorMsg, setErrorMsg] = useState(false);

  if (!isOpen) return null;
  const handleSelectedImage = (event) => {
    const imageFile = event.target.files[0];
    setImage(imageFile);

    if (!imageFile) {
      setInvalidImage(true);
      setErrorMsg(true);
      return false;
    }

    if (!imageFile.name.match(/\.(pdf)$/)) {
      setInvalidImage(true);
      setErrorMsg(true);
      return false;
    } else {
      setInvalidImage(false);
      setErrorMsg(false);
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    popupCloseSubmit();
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ background: "white", padding: 20, borderRadius: 5 }}>
        <div class="modal-dialog">
          {!loading ? (
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title ">{hint}</h5>
                <FontAwesomeIcon
                className="close-popup-btn"
                  onClick={() => {
                    onClose();
                  }}
                  icon={faWindowClose}
                />
              </div>
              <div class="modal-body pt-3 pb-3 text-start">
                {showTitle && (
                  <input
                    className="title-input"
                    type="text"
                    id="title"
                    placeholder="Enter the Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                )}
                {showLink && (
                  <input
                    className="title-input"
                    type="text"
                    id="link"
                    placeholder="Enter the Link"
                    onChange={(e) => setLink(e.target.value)}
                  />
                )}
                {showImage && (
                  <div className="mt-2">
                    <input type="file" onChange={handleSelectedImage} />
                  </div>
                )}
                {errorMsg && (
                  <p className="text-danger">Select PDF File is not Correct. </p>
                )}
              </div>
              <div class="modal-footer">
                <button
                  onClick={() => handleSubmit()}
                  type="button"
                  class="btn btn-success"
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div class="modal-content-loading">
              <div className="d-flex justify-content-center">
                <div className="loading-spinner-popup"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashPdfPopupScreen;
