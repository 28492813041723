import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./CarouselScreen.css";

import {
  auth_token,
  auth_token_name,
  resource_url,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const CarouselScreen = () => {
  const [trainData, setTrainData] = useState([]);

  useEffect(() => {
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, 13);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="carousel_video_bg">
      <div className="container">
        <div className="carousel-div ">
          <Carousel>
            {trainData.map((item, key) => (
              <Carousel.Item key={key}>
                <div className="container-back-front">
                  <div className="front">
                    <img
                      className="d-block fluid img-class"
                      src={resource_url + item.image}
                      alt={item.title}
                    />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CarouselScreen;
