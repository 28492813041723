/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./SnakeRearScreen.css";

import CategoryTitle from "../Webcontroller/CategoryTitle";
import ImageLayout from "../Webcontroller/ImageLayout";
import {
  auth_token,
  auth_token_name,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const SnakeRearScreen = () => {
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, 2);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        data.error === true ? setLoading(false) : setLoading(true);
        setTrainData(data.data);
      })
      .catch((error) => {});
  }, []);

  const loadingView = () => {
    return (
      <div className="pt-5 pb-5  slider_screen" id="snake_rear">
        <CategoryTitle name="Snake Rearing" />
        <div className="container">
          <div className="row  justify-content-start ">
            {trainData.map((item, key) => (
              <ImageLayout
                key={key}
                title={item.title}
                image={item.image}
                link={item.image}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {trainData.length > 0
        ? loadingView()
        : loading && (
            <div className="d-flex justify-content-center loading-bg">
              <div className="loading-spinner"></div>
            </div>
          )}
    </div>
  );
};

export default SnakeRearScreen;
