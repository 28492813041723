import "./App.css";

import hotline from './Resource/hotline_gif.gif';
import BrowseRouterScreen from "./components/BrowseRouter/BrowseRouterScreen";
import ScrollButton from "./components/ScrollButton/ScrollButton";

function App() {
  return (
    <div className="main-div">
      <div className="">
        <BrowseRouterScreen />
      </div>
      <div className="arrow-up">
        <ScrollButton />
      </div>

      <div className="fixed-hotline-div">
        <a href="https://vrc.cmc.edu.bd/Files/resources/hotline/hotline.jpg">
        <img className="" src={hotline} alt="" /></a>
      </div>
    </div>
  );
}

export default App;
