/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import CategoryTitle from "../Webcontroller/CategoryTitle";
import ImageLayout from "../Webcontroller/ImageLayout";

import {
  auth_token,
  auth_token_name,
  segment_detail_url,
  segment_id_name,
} from "../Webcontroller/Webcontroller";

const SupportProgramScreen = () => {
  const segmentId = 19;
  const [trainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Training
    const trainData = new FormData();
    trainData.append(auth_token_name, auth_token);
    trainData.append(segment_id_name, segmentId);
    setLoading(true);

    fetch(segment_detail_url, {
      method: "POST",
      body: trainData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTrainData(data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  const mainView = () => {
    return (
      <div className="pt-5 pb-5  slider_screen" id="snake_rescue">
        <CategoryTitle name="Support Program" />
        <div className="container">
          <div className="row  justify-content-start ">
            {trainData.map((item) => (
              <ImageLayout
                title={item.title}
                image={item.image}
                link={item.image}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div id="snake_rescue">
      {trainData.length > 0
        ? mainView()
        : loading && (
            <div className="d-flex justify-content-center loading-bg">
              <div className="loading-spinner"></div>
            </div>
          )}
    </div>
  );
};

export default SupportProgramScreen;
