import { motion } from "framer-motion";
import React from "react";
import "./AboutVRC.css";

const containerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.5,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      mass: 0.4,
      damping: 8,
      staggerChildren: 0.5,
      when: "beforeChildren",
    },
  },
};

const childVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};
const AboutVrcScreen = () => {
  const about_vrc_venom =
    "Venom Research Centre (VRC) Bangladesh, was established in 2018 as a project run by Non-Communicable Disease Control program under the Directorate General of Health Services of the Ministry of Health and Family Welfare, Government of the People's of Bangladesh. It is a collaborative endeavor of the Department of Medicine, Chittagong Medical College; Department of Zoology, University of Chittagong; Department of Tropical Medicine and Public Health, Goethe University, Frankfurt am Main, Germany; Toxicology Society of Bangladesh; and Bangladesh Association for Advancement of Tropical Medicine. This centre is hosted and managed by the Chittagong Medical College.";
  const about_vrc_aim =
    "The immediate aim is to collect and rear up specimens of all the medically important snakes from different parts of the country and to collect venoms from them. The objectives are to obtain representative samples of venoms, standardize them and use for venomics and proteomics studies and to make representative, effective and affordable antivenoms.";

  const abut_vrc_housing =
    "The centre housing more than 300 specimens representing Cobra (Najakaouthia, N. naja), Kraits (Bungarus caeruleus, B. fasciatus, B. lividus, B. niger and B. walli), and Vipers (Trimeresurusalbolabris, T. erythrurus and Daboia russelii). It has a facility of 3000 square feet serpentarium for housing snakes and venom extraction facilities as well as other resources viz., Quarantine Room, Ward Room, Snake Clinic, Mouse Breeding Room, Laboratory Room, etc. A snakebite clinic is also also being operated under the supervision of the centre inthe hospital to record snakebites cases.";

  return (
    <div className="container pt-5 pb-5" id="about_vrc">
      <div className="bg-white  bg-about-card">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <h3 className=" about-bg-h3">About VRC</h3>

          <motion.p variants={childVariants} className="bg-about-p">
            {about_vrc_venom}
          </motion.p>
          <motion.p variants={childVariants} className="bg-about-p">
            {about_vrc_aim}
          </motion.p>
          <motion.p variants={childVariants} className="bg-about-p">
            {abut_vrc_housing}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutVrcScreen;
