import React from "react";
import "./ContactScreen.css";

const ContactScreen = () => {
  return (
    <div className="bg-div" id="office_contact">
      <div className="container pt-5 pb-5 ">
        <div className="bg-white bg-contact-card">
          <h3 className=" bg-contact-h3 text-center">Contact Us</h3>
          <div className="d-flex justify-content-start align-items-start ">
            <p className="p-contact-us ">
              <b className="b-contact-vrc">Venom Research Centre, Bangladesh</b>{" "}
              <br />
              Department of Medicine <br />
              Chittagong Medical College <br />
              K.B. Fazlul Kader Road, Panchlaish, Chattogram-4203 <br />
              Mobile: <b>+880 1857 966486</b> <br />
              Email: <b>vrc.bd.org@gmail.com</b> <br />
              Website: <b>vrcbangladesh.org</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
