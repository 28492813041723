import React from 'react';
import './PopupScreen.css';

const PopupScreen = ({ isOpen, onClose, popupCloseSubmit, title, msg, btn="Submit" }) => {
    if (!isOpen) return null;
    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: 'white', padding: 20, borderRadius: 5 }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class=" error-title text-red">{title}</h5>
                        </div>
                        <div class="modal-body">
                            <p>{msg}</p>
                        </div>
                        <div class="modal-footer">
                            <button onClick={() => popupCloseSubmit()} type="button" class="btn btn-success">{btn}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PopupScreen;