import React from "react";
import "./Webcontroller.css";

const CategoryTitle = ({ name }) => {
  return (
    <div className="category-div text-center">
      <h2 className="category-title">{name}</h2>
    </div>
  );
};

export default CategoryTitle;
